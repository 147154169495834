import styled from "styled-components";
import { breakpoints, toRem } from "utils/mixins";

export const NewsStyles = styled.div`
  width: 100%;
  padding: 10px 0 10px 0;
  margin-bottom: 30px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: red;
  }

  .news-content {
    width: 100%;
    padding: 10px 0 0 0;
  }

  h3 {
    text-align: left;
  }

  .news-image {
    width: 100%;
  }

  button {
    margin: 0 0 10px 0;
    font-weight: bold;
  }

  @media ${breakpoints.laptop} {
    padding: ${toRem(50)};
    display: flex;
    margin-bottom: 0;
    .news-content {
      width: 50%;
      padding-left: 80px;
    }
    .news-image {
      width: 50%;
    }
    button {
      margin: 0px;
    }
  }
`;
