import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import NewsCard from "components/newsCard";

const NoticiasPage = (props) => {
  //PROPS
  const {
    data: {
      allWpNoticia: { edges },
      page: {
        title,
        news_page: { fachada }
      }
    }
  } = props;
  //PROPS

  const [lazy, setLazy] = useState(1);
  const [items, setItems] = useState([]);

  function compare(a, b) {
    if (
      Date.parse(a.node.news_post.dataDaNoticia) >
      Date.parse(b.node.news_post.dataDaNoticia)
    ) {
      return -1;
    }
    if (
      Date.parse(a.node.news_post.dataDaNoticia) <
      Date.parse(b.node.news_post.dataDaNoticia)
    ) {
      return 1;
    }
    return 0;
  }

  edges.sort(compare);

  useEffect(() => {
    let it = edges.slice(0, lazy * 4);

    let newA = [];

    let itemss = newA.concat(it);

    setItems(itemss);
    
  }, [lazy]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      ticking = false;
      if(scrollY + window.innerHeight > document.body.scrollHeight - 200 && lazy < Math.ceil(edges.length / 4)) {
        setLazy(lazy + 1)
      }
     
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    //console.log("adicionar");
    window.addEventListener("scroll", onScroll, true);

    return () => {
      //console.log("saiu");
      window.removeEventListener("scroll", onScroll, true);
    };
  }, [lazy]);

  return (
    <main className="main">
      <div className="section section--unlimited">
        <div className="section-banner">
          <Img
            fluid={fachada?.localFile?.childImageSharp.fluid}
            alt={fachada?.altText}
          />
          <h2>{title}</h2>
        </div>

        <section className="section">
          <div className="container">
            {items.map((item, index) => {
              if (item.node?.news_post?.imagemListagem) {
                return <NewsCard data={item} key={index} />;
              } else {
                return null;
              }
            })}
          </div>
        </section>
      </div>
    </main>
  );
};

export const query = graphql`
  query Noticias($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      news_page {
        fachada {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
        }
      }
    }
    allWpNoticia {
      edges {
        node {
          uri
          news_post {
            dataDaNoticia
            fieldGroupName
            introthumbnailText
            title
            imagemListagem {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            conteudo {
              ... on WpNoticia_NewsPost_Conteudo_Texto {
                blockText
              }
            }
          }
        }
      }
    }
  }
`;

export default NoticiasPage;
