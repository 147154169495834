import React from "react";

import { NewsStyles } from "./NewsCard.styles";
import Img from "gatsby-image";
import Link from "gatsby-link";
import Button from "../button";
import { red, white } from "theme/colors";
import HTMLReactParser from "html-react-parser";

const NewsCard = (props) => {
  const { data } = props;

  let news_post = data.node.news_post;

  return (
    <NewsStyles>
      <div className="news-image">
        <Img
          fluid={news_post.imagemListagem?.localFile?.childImageSharp.fluid}
          alt={news_post.imagemListagem?.altText}
        />
      </div>
      <div className="news-content">
        <h3>{news_post.title}</h3>
        <h4>{news_post.dataDaNoticia}</h4>
        {news_post.introthumbnailText &&
          HTMLReactParser(news_post.introthumbnailText)}
        <Link to={data.node.uri}>
          <Button
            text="LER MAIS"
            backgroundColor={red.default}
            color={white.default}
            minWidth
          />
        </Link>
      </div>
    </NewsStyles>
  );
};

export default NewsCard;
